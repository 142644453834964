import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import { useEffect, useState } from "react";
import Carousel from "./Carousel";
import { Box, Typography } from "@mui/material";
import Typing from "./Typing";
import carousel_bg from "assets/static/landing/carousel_bg.png";
import { Link } from "react-router-dom";
import { FaMailBulk } from "react-icons/fa";
import styles from './styles.module.css'

const Landing = ({ landing_data, dark_mode }) => {
  const [slide_index, setSlideIndex] = useState(0);
  const [interval_timer, setIntervalTimer] = useState(8400);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSlideIndex((slide_index) =>
  //       slide_index === landing_data.length - 1 ? 0 : slide_index + 1
  //     );
  //   }, interval_timer);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
      id="home"
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
            width: "100%",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            justifyContent: {
              xs: "center",
              sm: "center",
            },
            alignItems: {
              xs: "center",
              sm: "center",
            },
            textAlign: {
              xs: "center",
              sm: "center",
              md: "left",
            },
            gap: {
              xs: "10rem",
              sm: "10rem",
              md: "10rem",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems:{
                xs: "center",
                sm: "center",
                md: "flex-start",
              },
              gap: "3%",
              zIndex: 2,
              gap: "2rem",

              // position: "relative",
              // bottom: {
              //   xs: "20vh",
              //   sm: "20vh",
              //   md: "0",
              // },
            }}
          >
            <Typography
              // variant="h3"
              sx={{
                color: "var(--text-color1)",
                fontWeight: "bold",
                fontSize: "2.5rem",
              }}
            >
              Descubra o poder do uso de inteligência artificial personalizada
              para o seu negócio
            </Typography>

            <Typing {...{ landing_data, slide_index, setSlideIndex }} />

            <Typography
              sx={{
                color: "var(--text-color1)",
                fontWeight: "bold",
                fontSize: "1.4rem",
              }}
            >
              Nossa missão é desenvolver soluções inovadoras que agregam valor
              aos negócios dos nossos clientes.
            </Typography>

            <Link to={"/contato"}>
              <button
              className={styles.contactUsButton}
                style={{
                  letterSpacing: "0.1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaMailBulk
                  style={{
                    marginRight: "1rem",
                    fontSize: "1.6rem",
                  }}
                />
                <span style={{ zIndex: 2, position: "relative" }}>
                  Entrar em contato
                </span>
              </button>
            </Link>
          </Box>

          <Box
            sx={{
              width: "30%",
              position: "relative",
              backgroundColor: "red",
            }}
          >
            <img
              src={carousel_bg}
              alt="Fundo do Carousel"
              style={{
                position: "absolute",
                top: "50%",
                left: "36%",
                transform: "translate(-50%, -50%) scale(1.4)",
                opacity: "var(--white-img-opacity)",
              }}
            />
            <Carousel {...{ slide_index, landing_data, interval_timer, dark_mode }} />
          </Box>
        </Box>
      </Container>
    </CustomSection>
  );
};

export default Landing;
