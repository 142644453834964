import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { URL } from "utilities/variables";
import AddPost from "./AddPost";
import { CircularProgress } from "@mui/material";
import { get } from "utilities/requests";

const EditTag = () => {
  const params = useParams();
  const id = params.id;
  const [state, setState] = React.useState({
    data: {},
    token: "",
    redirect: false,
  });
  state.token = useSelector((state) => state.AppReducer.token);

  const [token, setToken] = React.useState(
    useSelector((state) => state.AppReducer.token)
  );

  // React.useEffect(() => {
  //   if (id !== null) {
  //     // fetch(`${URL}api/blog/tags/reply/${id}`, {
  //     //   method: 'GET',
  //     //   headers: {
  //     //     Accept: 'application/json',
  //     //     Authorization: `Bearer ${token}`,
  //     //     // 'Content-Type': 'application/json',
  //     //   }
  //     // })
  //     //   .then(async (response) => {
  //     //     const json = await response.json();
  //     //     return json;
  //     //   }).then(async (json) => {
  //     //     setState({ ...state, data: json.tag, redirect: true })
  //     //   });
  //   }
  // }, []);

  const getto = async () => {
    const res = await get(`blog/tags/reply/${id}`);

    if (res.status === 200) {
      setState({ ...state, data: res.tag, redirect: true });
    }
  };

  React.useEffect(() => {
    if (id !== null) {
      getto().catch((e) => {
        console.log(e);
      });
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center">
        {!state.redirect && <CircularProgress color="secondary" />}
      </div>
      {state.redirect && <AddPost edit={state.data} />}
    </>
  );
};

export default EditTag;
