import { Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import ScrollTransition from "components/ScrollTransition";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { get } from "utilities/requests";
import { STORAGE_URL } from "utilities/variables";
import TestimonialCard from "./TestimonialCard";

const responsive = {
  0: {
    items: 1,
  },
  480: {
    items: 1,
  },
  780: {
    items: 2,
  },
  1300: {
    items: 3,
  },
};

const Depoimentos = () => {
  const [testimonies, setTestimonies] = useState([]);
  const [readyToRender, setReadyToRender] = useState(true);

  const getto = async () => {
    const res = await get(`testimonials`);

    if (res.httpCode === 200) {
      let temp_testimonials = res.testimonials.map((data) => {
        return {
          name: data.name,
          title: data.title,
          text: data.text,
          avatar: data.avatar_path,
          occupation: data.occupation,
        };
      });
      setTestimonies(temp_testimonials);
      setReadyToRender(true);
    }
  };

  useEffect(() => {
    getto().catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    readyToRender &&
    testimonies.length > 0 && (
      <CustomSection
        style={{
          overflow: "hidden",
          position: "relative",
          minHeight: "auto",
        }}
        id="depoimentos"
        bg_color="var(--background-color1)"
        remove_border
      >
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.4rem",
            margin: "auto",
            paddingBottom: "8rem",
            width: "100vw",
          }}
        >
          {/* <ScrollTransition>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Depoimentos
            </Typography>
          </ScrollTransition>

          <ScrollTransition>
            <Typography
              variant="p"
              component="p"
              sx={{
                textAlign: "center",
                margin: "0 auto",
                marginBottom: "4rem",
                fontWeight: "normal",
                fontSize: "1.1rem",
                maxWidth: "30rem",
                "&:after": {
                  content: "''",
                  display: "block",
                  marginTop: "30px",
                  width: "40px",
                  borderTop: "2px solid #444",
                  margin: "30px auto 0",
                },
              }}
            >
              O que nossos clientes e parceiros falam de nós
            </Typography>
          </ScrollTransition> */}

          <ScrollTransition>
            <OwlCarousel
              className="owl-theme"
              loop
              dots={false}
              nav={false}
              items={3}
              center
              autoplay={true}
              autoplayTimeout={6000}
              responsive={responsive}
            >
              {testimonies.map((testimony, index) => (
                <TestimonialCard key={index} {...testimony} />
              ))}
            </OwlCarousel>
          </ScrollTransition>
        </Container>
      </CustomSection>
    )
  );
};

export default Depoimentos;
