import Landing from "./Landing";
import Servicos from "./Servicos";
import SobreNos from "./SobreNos";
import Clientes from "./Clientes/Clientes";
import Contato from "../Contato";

import ai from "assets/static/landing/ai.svg";
import dev from "assets/static/landing/dev.svg";
import analytics from "assets/static/landing/analytics.svg";
import team from "assets/static/landing/team.svg";

const HomePage = ({ dark_mode }) => {
  const landing_data = [
    {
      carousel_text:
        "Crie soluções de software personalizadas para o seu <a href='/'>sucesso</a>",
      title:
        "Somos especialistas em <a href='test'>Soluções Customizadas de Software</a>",
      text: "Desenvolvemos software personalizado adaptado às necessidades de cada cliente, trabalhando juntos em todas as etapas do processo para garantir soluções robustas, escaláveis e seguras, utilizando as últimas tecnologias e práticas de desenvolvimento.",
      image: dev,
      glowColor: "rgba(136, 58, 214)",
    },
    {
      carousel_text:
        "Inovação impulsionada pela <a href='/'>Inteligência Artificial</a>",
      title:
        "Somos especialistas em <a href='test'>Inteligência Artificial</a>",
      text: "Criamos soluções de IA personalizadas, analisando dados e automatizando processos usando aprendizado de máquina, processamento de linguagem natural e reconhecimento de imagem, trabalhando em estreita colaboração com os clientes para entender suas necessidades.",
      image: ai,
      glowColor: "rgba(136, 58, 214)",
    },
    {
      carousel_text:
        "Transforme seus dados em insights valiosos por meio de <a href='/'>soluções customizadas</a>",
      title:
        "Somos especialistas em <a href='test'>Consultoria em Inovação</a>",
      text: "Nós oferecemos serviços de consultoria em inovação para ajudar nossos clientes a identificar oportunidades de negócios e criar soluções inovadoras. Nós usamos metodologias de design thinking, brainstorming e prototipagem rápida para ajudar nossos clientes a pensar de forma criativa e desenvolver novas ideias.",
      image: analytics,
      glowColor: "rgba(136, 58, 214)",
    },
    {
      carousel_text:
        "Está pronto para o <a href='/'>futuro</a>? A nossa equipe pode te ajudar a chegar até lá",
      title:
        "Somos especialistas em <a href='test'>Consultoria em Inovação</a>",
      text: "Nós oferecemos serviços de consultoria em inovação para ajudar nossos clientes a identificar oportunidades de negócios e criar soluções inovadoras. Nós usamos metodologias de design thinking, brainstorming e prototipagem rápida para ajudar nossos clientes a pensar de forma criativa e desenvolver novas ideias. ",
      image: team,
      glowColor: "rgba(136, 58, 214)",
    },
    // {
    //   carousel_text: "Acesse <a href='test'>nossos serviços</a> para mais informações.",
    //   title: "Acesse <a href='test'>nossos serviços</a> para mais informações.",
    //   image: services,
    //   glowColor: "rgba(227, 89, 89,255)",
    // },
  ];

  return (
    <>
      <Landing {...{ landing_data, dark_mode }} />
      <SobreNos {...{ dark_mode }} />
      <Servicos {...{ landing_data, dark_mode }} />
      <Clientes />
      {/* <Depoimentos /> */}
      {/* clientes + depoimentos (mesma secao) sem o texto do depoimentos */}
      <Contato />
    </>
  );
};

export default HomePage;
