import { Skeleton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Card from "../Card";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useState } from "react";
import { useEffect } from "react";
import RenderTags from "./RenderTags";

import empty from "assets/static/empty.svg";
import { URL } from "utilities/variables";
import CustomSection from "components/CustomSection";
import ScrollTransition from "components/ScrollTransition";

const BrowseBlog = () => {
  const [posts, setPosts] = useState(["loading"]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(`${URL}api/blog/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // 'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setPosts(json.blog.data || []);
        setIsLoading(false);
      });
  }, []);

  const renderSkeleton = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          marginTop: "1rem",
          justifyContent: "space-between",
          opacity: 0.5,
        }}
      >
        {[...Array(9).keys()].map((item) => (
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "0.6rem",
              width: "30rem",
              height: "20rem",
              backgroundColor: "var(--background-color1)",
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          paddingTop: {
            xs: "6rem",
            sm: "6rem",
            lg: "8rem",
          },
          paddingBottom: "6rem",
        }}
      >
        {/* render all tags */}
        <ScrollTransition delay={300}>
          <div>
            <Typography
              variant="h5"
              sx={{
                alignItems: "center",
                display: "flex",
                marginBottom: "1rem",
                gap: "0.5rem",
                padding: {
                  xs: "0 1rem",
                  sm: "0",
                },
              }}
            >
              <FiberManualRecordIcon sx={{ color: "var(--accent-color1)" }} />{" "}
              Explorar
            </Typography>
            <RenderTags />
          </div>
        </ScrollTransition>

        <ScrollTransition delay={300}>
          <Typography
            variant="h5"
            sx={{
              alignItems: "center",
              marginTop: "2rem",
              display: "flex",
              gap: "0.5rem",
              padding: {
                xs: "0 1rem",
                sm: "0",
              },
            }}
          >
            <FiberManualRecordIcon sx={{ color: "var(--accent-color1)" }} />{" "}
            Mais recente
          </Typography>

          {!isLoading ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                marginTop: "1rem",
                // justifyContent: "space-between",
              }}
            >
              {/* {posts && posts[0] !== 'loading' && posts[0] && <Card {...posts[0]} firstCard />} */}
              {posts &&
                posts?.map((post, index) => {
                  if (posts[0] === "loading") return renderSkeleton();
                  // if (index === 0) return null;
                  return <Card {...post} />;
                })}
              {posts?.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    height: "100vh",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    backgroundColor: "var(--background-color0)",
                    zIndex: 2,
                    padding: "0 1rem",
                  }}
                >
                  <img
                    src={empty}
                    alt="empty"
                    style={{
                      width: "60%",
                      maxWidth: "22rem",
                      display: "block",
                      objectFit: "contain",
                    }}
                  />
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: {
                        xs: "2rem",
                        sm: "2.5rem",
                        md: "3.2rem",
                      },
                    }}
                  >
                    Por hora, não há nada aqui.
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Mas fique ligado porque em breve teremos novidades!
                  </Typography>
                </div>
              )}
            </div>
          ) : (
            renderSkeleton()
          )}
        </ScrollTransition>
      </Container>
    </CustomSection>
  );
};

export default BrowseBlog;
