import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import about_us from "assets/static/landing/about_us.svg";
import { Typography, Box } from "@mui/material";
import team from "assets/static/landing/team.jpg";
import mission from "assets/static/landing/mission.jpg";
import do_img from "assets/static/landing/do.jpg";
import styles from "./styles.module.css";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ScrollTransition from "components/ScrollTransition";

const SobreNos = ({ on_top, dark_mode }) => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        position: "relative",
      }}
      id="quem-somos"
      bg_color="var(--background-color1)"
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          minHeight: "100vh",
          padding: "2rem 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            gap: "2rem",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "60%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "left",
              },
              alignItems: "flex-start",
              margin: {
                xs: "auto auto 0 auto",
                sm: "auto auto 0 auto",
                md: "auto",
              },
            }}
          >
            <Box
              sx={{
                borderLeft: {
                  xs: "none",
                },
                padding: "1rem",
                paddingLeft: {
                  xs: "0",
                  sm: "2rem",
                  md: "2rem",
                },
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <ScrollTransition>
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "2rem",
                      sm: "2.8rem",
                      md: "3.8rem",
                    },
                  }}
                >
                  A <span style={{ color: "var(--accent-color3)" }}>E</span>NTER
                  <span style={{ color: "var(--accent-color3)" }}>S</span>CIENCE
                </Typography>
              </ScrollTransition>

              <ScrollTransition
                sx={{
                  maxWidth: {
                    xs: "100%",
                    sm: "100%",
                    md: "80%",
                  },
                  padding: {
                    xs: "0 2rem",
                    sm: "0 2rem",
                    md: "0",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: {
                      xs: "1.2rem",
                      sm: "1.3rem",
                      md: "1.4rem",
                    },
                    lineHeight: "2rem",
                    textAlign: 'justify',
                  }}
                >
                  Nosso objetivo é <b>criar soluções</b> que ajudem nossos clientes{" "}
                  <b>atingir seus objetivos de negócios</b>. Nós acreditamos em
                  trabalho de colaboração com nossos clientes para entender suas
                  necessidades únicas e oferecer soluções que excedam suas
                  expectativas. Nós nos esforçamos para ser um parceiro
                  tecnológico confiável e líder em inovação, oferecendo soluções
                  inovadoras, eficazes e personalizadas que ajudam nossos
                  clientes a alcançar o sucesso a longo prazo.
                </Typography>
              </ScrollTransition>
            </Box>
          </Box>
          <Box
            sx={{
              width: "40%",
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              maxWidth: "28rem",
              margin: {
                xs: "0 auto auto auto",
                sm: "0 auto auto auto",
                md: "auto",
              },
            }}
          >
            <ScrollTransition>
              <img
                src={about_us}
                alt="Sobre nós"
                style={{
                  width: "120%",
                  position: "relative",
                  top: "5rem",
                  right: "20%",
                  zIndex: "0",
                  background: !dark_mode && `radial-gradient(circle, rgba(124, 50, 225, 1) -50%, rgba(255,255,255,0) 70%)`,
                }}
              />
            </ScrollTransition>
          </Box>
        </Box>

        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
            },
            flexDirection: "column",
          }}
        >
          <ScrollTransition>
            <img
              src={about_us}
              alt="Sobre nós"
              style={{
                width: "100%",
                position: "relative",
                top: "5rem",
                zIndex: "0",
                margin: "auto",
                WebkitFilter: `drop-shadow(0px 0px 198px rgba(124, 50, 225, 1))`,
                filter: `drop-shadow(0px 0px 198px rgba(124, 50, 225, 1))`,
              }}
            />
          </ScrollTransition>
        </Box>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4rem 2rem",
            padding: "8rem 1rem 4rem 1rem",
          }}
        >
          <ServiceCard
            title="QUEM SOMOS?"
            description="Somos uma empresa especializada no desenvolvimento de soluções de software personalizadas para cada cliente, trabalhando em colaboração desde a concepção até a manutenção contínua para garantir que cada solução seja eficaz e única."
            image={mission}
            speed={15}
          />
          <ServiceCard
            title="POR QUE NOS ESCOLHER?"
            description="A EnterScience se destaca por seu compromisso inabalável com a excelência técnica, oferecendo soluções personalizadas que atendem às necessidades exclusivas de cada cliente. Nossa equipe é qualificada em programação, IA e análise de dados, atualizada com as últimas tendências tecnológicas."
            image={team}
            speed={30}
          />
          <ServiceCard
            title="O QUE NÓS FAZEMOS?"
            description="Desenvolvemos soluções de software personalizadas para ajudar os clientes a superar seus desafios mais complexos, desde a concepção até a manutenção contínua. A equipe técnica está comprometida em criar soluções sob medida que atendam às necessidades de cada cliente e ajudem a alcançar o sucesso a longo prazo."
            image={do_img}
            speed={45}
          />
        </div>
      </Container>
    </CustomSection>
  );
};

export default SobreNos;

const ServiceCard = ({ title, description, image, speed }) => {
  return (
    <Box
      className={`${styles.serviceCard} Transition-1`}
      sx={{
        width: "24rem",
        flexGrow: 1,
        padding: "0.8rem",
        borderRadius: "0.8rem",
        position: "relative",
        // transition: "all 0.3s",
        cursor: "pointer",
        "&:hover": {
          // backgroundColor: "var(--background-0)",
          transform: "translateY(-1rem)",
          boxShadow: "0 1rem var(--background-2)",
        },
        perspective: "1500px",
      }}
    >
      {/* <div className={`${styles.cardFlipEffect} Transition-1`} /> */}
      <img
        src={image}
        alt="Ícone do cartão"
        style={{
          width: "100%",
          height: "14rem",
          marginBottom: "1rem",
          objectFit: "cover",
          borderRadius: "0.8rem 0.8rem 0 0",
          WebkitMaskImage:
            "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
          maskImage: "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))",
          zIndex: 2,
        }}
      />
      <h3
        style={{
          fontSize: "1.6rem",
          fontWeight: "bold",
          color: "var(--text-main-2)",
          position: "relative",
          width: "max-content",
          zIndex: 2,
          margin: "0 auto",
          bottom: "3rem",
        }}
      >
        {title}
      </h3>
      <p
        style={{
          fontSize: "1.2rem",
          fontWeight: "normal",
          color: "var(--text-main-1)",
          textAlign: "justify",
          position: "relative",
          bottom: "2rem",
          zIndex: 2,
        }}
      >
        {description}
      </p>
    </Box>
  );
};
