import React from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  ThemeProvider,
} from "@mui/material";
import { STORAGE_URL, URL } from "utilities/variables";
import Theme from "../../Theme/Theme";

// mui icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

const ListPost = () => {
  const history = useNavigate();

  const [posts, setPosts] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current_page: "",
    total_pages: "",
    per_page: "",
  });
  const [pageNumber, setPageNumber] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [token, setToken] = React.useState(
    useSelector((state) => state.AppReducer.token)
  );

  const getFormattedDate = (date) => {
    const d = new Date(date);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  React.useEffect(() => {
    setLoadingSearch(true);
    fetch(`${URL}api/blog/list?page=${pageNumber}&search=${search}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // 'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setPosts(json.blog.data);
        setPagination(json.pagination);
        setLoading(false);
        setLoadingSearch(false);
      });
  }, [pageNumber, search, reload]);

  function renderPosts() {
    return (
      <>
        <tbody>
          {posts?.map((post, index) => {
            return (
              <tr key={index}>
                <td>
                  <div
                    style={{
                      width: "8rem",
                      height: "4rem",
                      backgroundImage: `url(${STORAGE_URL}${post.background_image_path})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "0.5rem",
                    }}
                  ></div>
                </td>
                <td style={{ textAlign: "left" }}>{post.title}</td>
                <td style={{ textAlign: "left" }}>{post.summary}</td>
                <td>{post.tags.map(
                  (tag, index) => `${tag.label}${index + 1 < post.tags.length ? ", " : ""}`
                )}</td>
                <td>{getFormattedDate(post.created_at)}</td>
                <td>{post.author}</td>

                <td>
                  <IconButton size="large">
                    <EditIcon
                      size="large"
                      color="secondary"
                      onClick={() => EditPost(post.id)}
                    />
                  </IconButton>

                  <IconButton size="large">
                    <DeleteIcon color="error" onClick={() => Delete(post.id)} />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }

  async function Delete(id) {
    try {
      let result = await fetch(`${URL}api/blog/delete/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
      }).then(() => { 
        setReload(!reload);
      });
      // setState({...state, })
    } catch {
      console.log("deu bigode");
    }
  }

  function EditPost(id) {
    history("/dashboard/blog/edit/" + id);
  }

  let timer;
  function searchFunction(e) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearch(e.target.value);
    }, 750);
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-column">
              <h1 className="header-title">Lista de postagens do blog</h1>
              <Input
                placeholder="Buscar..."
                endAdornment={
                  loadingSearch ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SearchIcon />
                  )
                }
                onChange={(e) => searchFunction(e)}
              ></Input>
            </div>
            <div className="align-self-end ms-auto">
              <Button
                variant="contained"
                size="large"
                endIcon={<AddRoundedIcon />}
                onClick={() => history("/dashboard/blog/add")}
              >
                Adicionar
              </Button>
            </div>
          </div>
          <table className="table table-striped table-hover text-center mt-5">
            <thead className="text-center">
              <tr>
                <th scope="col">Capa</th>
                <th scope="col">Título</th>
                <th scope="col">Resumo</th>
                <th scope="col">Tag</th>
                <th scope="col">Data</th>
                <th scope="col">Autor</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            {posts && loading == false && renderPosts()}
          </table>
          {/* {loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>} */}
          {/* {posts && pagination()} */}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ListPost;
