import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Button, ThemeProvider } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import React from 'react'
import Input from './Input';
import Switch from './Switch';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { URL } from 'utilities/variables';
import { useNavigate } from 'react-router-dom';
import Theme from '../../Theme/Theme';

const AddUser = ({ edit }) => {
  const [form, setForm] = React.useState({
    name: { label: 'Nome*', value: '', error: false },
    email: { label: 'Email*', value: '', error: false },
    password: { label: 'Senha*', value: '', error: false },
  })
  const [perm, setPerm] = React.useState({
    ADM: true,
    Professor: false,
    Colaborador: false,
    Aluno: false,
  })
  const [state, setState] = React.useState({
    token: '',
  })
  const [file, setFile] = React.useState('')
  const [saving, setSaving] = React.useState(false)
  const history = useNavigate();
  state.token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    if (edit) {
      setForm({
        ...form, name: { ...form.name, value: edit.user.name }, email: { ...form.email, value: edit.user.email },
        password: { ...form.password, value: edit.user.password }
      })
    }
  }, [])

  async function addUser(type) {
    const keys = Object.keys(form)
    let form2 = { ...form }
    keys.forEach(item => {
      if (form2[item].value == '') form2[item].error = true
    })
    if (validator.isEmail(form2.email.value)) {
      form2.email.error = false
    } else {
      form2.email.error = true
    }
    setForm(form2)

    if (!form2.name.error && !form2.email.error && !form2.password.error) {
      const formData = new FormData();
      formData.append('name', form.name.value);
      formData.append('email', form.email.value);
      formData.append('password', form.password.value);
      formData.append('file_path', file);

      // formData.append('adm', perm.ADM);
      // formData.append('prof', perm.Professor);
      // formData.append('colab', perm.Colaborador);
      // formData.append('student', perm.Aluno);
      if (edit) formData.append('id', edit.user.id)

      let result = await fetch(`${URL}api/user/${type}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${state.token}`,
        },
        body: formData,
      });
      renderInput()
      history('/dashboard/users');
    }
  }

  function renderInput() {
    const keys = Object.keys(form);
    return (
      keys.map(item => {
        return <Input key={item.label} item={item} form={form} setForm={(e) => setForm(e)} />
      })
    )
  }

  function renderSwitch() {
    const keys = Object.keys(perm);
    return (
      keys.map((item, index) => {
        return <Switch key={index} item={item} perm={perm} setPerm={(e) => setPerm(e)} />
      })
    )
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          <div className="row p-sm-5">
            <div className="col-12 col-md-8 m-auto">
              <h1 className='title mb-5'>USUÁRIO</h1>
              {renderInput()}
              <div className="d-flex mb-5">
                {/* <div className="me-3">
                  <h1 className='lead'><strong>Imagem</strong></h1>
                  <Button variant="contained" component="label" endIcon={<PhotoCamera />}>
                    {file ? file.name : 'Arquivo de Imagem'}
                    <input hidden onChange={(e) => setFile(e.target.files[0])} accept="image/*" multiple type="file" />
                  </Button>
                </div>
                <div className="ms-auto">
                  <h1 className='lead'><strong>Permissões</strong></h1>
                  {renderSwitch()}
                </div> */}
              </div>
              <div className="d-flex mt-5">
                <div className="align-self-center">
                  <Button variant="outlined" onClick={() => history('/dashboard/users')} startIcon={<ReplyAllIcon />}> Voltar</Button>
                </div>
                <div className="align-self-center ms-auto">
                  <LoadingButton loading={saving} onClick={() => edit ? addUser('update') : addUser('add')} loadingPosition="end" endIcon={<SaveIcon />} variant="outlined">Salvar</LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default AddUser