import {
  Autocomplete,
  Button,
  TextField,
  ThemeProvider,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import React from "react";
import Input from "./Input";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { STORAGE_URL, URL } from "utilities/variables";
import { useNavigate } from "react-router-dom";
import Theme from "../../Theme/Theme";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useEffect } from "react";
import { fileToBase64 } from "utilities/helpers";
import JoditEditor from "jodit-react";
import JoditConfig from "utilities/joditConfig";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import swal from 'sweetalert';
const descriptionConfig = new JoditConfig("jodit-upload-image").config();

const style_select = {
  padding: "0.4rem 1rem",
  border: 0,
  borderBottom: "0.1rem solid rgb(140, 140, 140)",
  marginBottom: "2rem",
};

const AddPost = ({ edit }) => {
  const [form, setForm] = React.useState({
    author: { label: "Autor", value: "" },
    title: { label: "Titulo", value: "" },
    summary: { label: "Resumo", value: "" },
  });
  const [token, setToken] = React.useState(
    useSelector((state) => state.AppReducer.token)
  );

  const [content, setContent] = React.useState("");

  const [post_tags, setPostTags] = React.useState([]);
  const [topics, setTopics] = React.useState([]);
  // const [isLoading, setIsLoading] = React.useState(false);

  const handleCardChange = (data) => {
    // check if the card already exists
    const cardExists = topics.find((card) => card.label === data.label);
    if (cardExists) {
      // if it exists, remove it
      setTopics(topics?.filter((card) => card.label !== data.label));
    } else {
      // if it doesn't exist, add it
      const the_card = post_tags.find((card) => card.label === data.label);
      setTopics([...topics, the_card]);
    }
  };

  const removeCard = (label) => {
    setTopics(topics?.filter((card) => card.label !== label));
  };

  const [state, setState] = React.useState({
    token: "",
  });
  const [error, setError] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [image_base64, setImage_base64] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const history = useNavigate();
  state.token = useSelector((state) => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/blog/tags/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setPostTags(json.tags);
      });
  }, []);

  useEffect(() => {
    fileToBase64(file).then((data) => {
      setImage_base64(data);
    });
  }, [file]);

  useEffect(() => {
    if (edit) {
      setForm({
        ...form,
        author: { ...form.author, value: edit.author },
        title: { ...form.title, value: edit.title },
        summary: { ...form.summary, value: edit.summary },
      });
      setTopics(edit.tags);
      setContent(edit.content);
      setImage_base64(STORAGE_URL + edit.background_image_path);
      setFile(1);
    }
  }, []);

  async function addPost(type) {
    const formData = new FormData();
    formData.append("author", form.author.value);
    formData.append("title", form.title.value);
    formData.append("summary", form.summary.value);

    topics?.map((item) => {
      formData.append("topics[]", JSON.stringify(item.id));
    });

    formData.append("content", content);

    if (file !== 1) {
      formData.append("background_image_path", file);
    }

    if (edit) formData.append("id", edit.id);

    setSaving(true);

    let result = await fetch(`${URL}api/blog/${type}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${state.token}`,
      },
      body: formData,
    })
      .then(async (response) => {
        const json = await response.json();
        return json;
      })
      .then(async (json) => {
        if (json.status === 200) {
          renderInput();
          history("/dashboard/blog/list");
        } else {
          // notification({ error: json.message, type: "error" });
          swal({
            title: "Erro!",
            text: json.message,
            icon: "error",
            button: "Ok",
          });
        }
        setSaving(false);
      });
  }

  function renderInput() {
    const keys = Object.keys(form);
    return keys.map((item) => {
      return (
        <Input
          key={item.label}
          item={item}
          form={form}
          setForm={(e) => setForm(e)}
        />
      );
    });
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          <div className="row p-sm-5">
            <div className="col-12 col-md-8 m-auto">
              <h1 className="title mb-5">Criar nova postagem</h1>
              {renderInput()}
              <h6>Selecione as tags</h6>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={post_tags || []}
                onChange={(event, newValue) => {
                  handleCardChange(newValue);
                }}
                sx={{ width: 300, marginBottom: "1rem" }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} label="Tags" />
                )}
              />
              <h6 style={{ marginTop: "1rem" }}>Tags selecionadas</h6>
              <div
                style={{
                  margin: "1rem 0",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "0.4rem",
                  marginBottom: "2rem",
                }}
              >
                {topics?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      background: "rgb(230,230,230)",
                      gap: "0.5rem",
                      width: "max-content",
                      borderRadius: "1rem",
                      padding: "0.2rem 1rem",
                      paddingRight: "0.6rem",
                    }}
                  >
                    {item.label}
                    <CloseIcon
                      onClick={() => removeCard(item.label)}
                      sx={{
                        cursor: "pointer",
                        opacity: 0.6,
                        "&:hover": {
                          opacity: 1,
                        },
                      }}
                    />
                  </div>
                ))}
              </div>
              {/* <select style={style_select}
              onChange={(e) => {
                setTopic(e.target.value);
              }}
              value={topic}
            >
              {post_tags.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select> */}
              <JoditEditor
                config={descriptionConfig}
                value={content}
                onChange={(value) => setContent(value)}
              />
              <div className="d-flex my-5">
                <div className="me-3">
                  <h1 className="lead">
                    <strong>Capa</strong>
                  </h1>
                  {/* < style={{width: '4rem', height: '8rem', position: 'relative'}}>
                </div> */}
                  <label
                    htmlFor="file-input-blog"
                    style={{
                      width: "22rem",
                      height: "12rem",
                      cursor: "pointer",
                      border: "0.2rem dashed gray",
                      borderRadius: "0.6rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {file ? (
                      <img
                        src={image_base64}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <AddRoundedIcon style={{ fontSize: "2.2rem" }} />
                    )}
                    <input
                      hidden
                      onChange={(e) => setFile(e.target.files[0])}
                      accept="image/*"
                      multiple
                      type="file"
                      id="file-input-blog"
                    />
                  </label>
                </div>
              </div>

              <span
                style={{
                  padding: "0.2rem 0.4rem",
                  borderRadius: "0.4rem",
                  backgroundColor: "#f1f1f1",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  width: "max-content",
                }}
              >
                <ErrorOutlineIcon sx={{ color: "var(--purple)" }} /> <b>Proporção de tela recomendada:</b> 16:9
              </span>

              <div className="d-flex mt-5">
                <div className="align-self-center">
                  <Button
                    variant="outlined"
                    onClick={() => history("/dashboard/blog/list")}
                    startIcon={<ReplyAllIcon />}
                  >
                    {" "}
                    Voltar
                  </Button>
                </div>
                <div className="align-self-center ms-auto">
                  <LoadingButton
                    loading={saving}
                    loadingPosition="end"
                    endIcon={<SaveIcon />}
                    variant="outlined"
                    onClick={() => (edit ? addPost("update") : addPost("add"))}
                  >
                    Salvar
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AddPost;
