import Dashboard from "pages/private/Dashboard";
import AddPost from "pages/private/Dashboard/pages/Blog/AddPost";
import EditPost from "pages/private/Dashboard/pages/Blog/EditPost";
import ListPost from "pages/private/Dashboard/pages/Blog/ListPost";
import AddTag from "pages/private/Dashboard/pages/Blog/Tags/AddPost";
import EditTag from "pages/private/Dashboard/pages/Blog/Tags/EditPost";
import ListTags from "pages/private/Dashboard/pages/Blog/Tags/ListPost";
import ListComplaints from "pages/private/Dashboard/pages/Complaints/ListNews";
import ListNews from "pages/private/Dashboard/pages/ContactUs/ListNews";
import PolicyAndTerms from "pages/private/Dashboard/pages/PolicyAndTerms";
import Testimonials from "pages/private/Dashboard/pages/Testimonials";
// import AddUser from "pages/private/Dashboard/pages/User/AddUser";
import ListUser from "pages/private/Dashboard/pages/User/ListUser";
import { Navigate, Route, Routes } from "react-router-dom";

import logo_black from "assets/static/logo/main_black.png";
import logo from "assets/static/logo/main_black.png";
import { Typography } from "@mui/material";
import ListWorkWithUs from "pages/private/Dashboard/pages/WorkWithUs/ListWorkWithUs";
import AddUser from "pages/private/Dashboard/pages/User/AddUser";
import EditUser from "pages/private/Dashboard/pages/User/EditUser";

const PrivateRoutes = ({ on_top }) => {
  return (
    <>
      {/* <Navbar {...{on_top}} /> */}

      <Routes>
        {/* index */}
        <Route path={"/*"} element={<Dashboard />}>
          <Route path="policy-and-terms" element={<PolicyAndTerms />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="contact-us" element={<ListNews />} />
          <Route path="complaints" element={<ListComplaints />} />
          <Route path="work-with-us" element={<ListWorkWithUs />} />


          {/* users */}
          <Route path="users" element={<ListUser />} />
          <Route path="users/add" element={<AddUser />} />
          <Route path="users/edit/:id" element={<EditUser />} />
          {/* <Route path="users/:id" element={<User />} /> */}

          {/* blog */}
          <Route path={'blog/list'} element={<ListPost />} />
          <Route path={'blog/add'} element={<AddPost />} />
          <Route path={'blog/edit/:id'} element={<EditPost />} />

          {/* blog tags */}
          <Route path={'blog/tags/list'} element={<ListTags />} />
          <Route path={'blog/tags/add'} element={<AddTag />} />
          <Route path={'blog/tags/edit/:id'} element={<EditTag />} />


          <Route path="*" element={<div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}>
            <img src={logo_black} alt="EnterScience Logotipo" 
              style={{width: "20rem", objectFit: "contain"}}
            />
            <Typography
              variant="h4"
              component="h4"
              style={{ color: "var(--text-reverse-1)", marginTop: "2rem", fontWeight: 'bold' }}
            >
              Seja bem-vindo(a) ao Dashboard
            </Typography>
          </div>} />

        </Route>

        {/* generic 404 */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>

      {/* <Footer /> */}
    </>
  )
}

export default PrivateRoutes;