import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import {
  IconButton,
  Input,
  ThemeProvider,
  Pagination,
  CircularProgress,
} from "@mui/material";
import swal from "sweetalert";
import Theme from "../../Theme/Theme";
import { URL } from "utilities/variables";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ListNews = () => {
  const [state, setState] = React.useState({
    news: [],
    pagination: { current_page: "", total_pages: "", per_page: "" },
    pageNumber: 1,
    loading: true,
    loadingSearch: false,
    reload: false,
    msg: false,
    token: "",
    search: "",
  });
  const history = useNavigate();
  state.token = useSelector((state) => state.AppReducer.token);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    fetch(
      `${URL}api/contact-us/list?page=${state.pageNumber}&search=${state.search}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${state.token}`,
          // 'Content-Type': 'application/json',
        },
      }
    )
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setState({
          ...state,
          news: json.news,
          pagination: json.pagination,
          loading: false,
          loadingSearch: false,
        });
      });
  }, [state.pageNumber, state.search, state.reload]);

  function showMessage(message) {
    console.log(message);
  }

  function Delete(id) {
    fetch(`${URL}api/contact-us/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      },
    });
  }

  function renderAlert(id) {
    return swal({
      title: "Deletar mensagem selecionada?",
      text: "Uma vez deletada, não dará para recuperá-la!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Mensagem deletada com sucesso!", {
          icon: "success",
        });
        Delete(id);
        setState({ ...state, pageNumber: 0, reload: !state.reload });
      }
    });
  }

  function ReplyMessage(id) {
    history("/dashboard/news/reply/" + id);
  }

  function pagination() {
    const { total_pages, per_page } = state.pagination;
    return (
      <div className="d-flex justify-content-end">
        <Pagination
          color="primary"
          shape="rounded"
          count={Math.ceil(total_pages / per_page)}
          page={state.pageNumber}
          onChange={(e, page) => setState({ ...state, pageNumber: page })}
        />
      </div>
    );
  }

  let timer;
  function search(e) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setState({ ...state, search: e.target.value, loadingSearch: true });
    }, 750);
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-column">
              <h1 className="header-title">Lista de Mensagens</h1>
              <Input
                placeholder="Buscar..."
                endAdornment={
                  state.loadingSearch ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SearchIcon />
                  )
                }
                onChange={(e) => search(e)}
              ></Input>
            </div>
          </div>
          <table className="table table-striped table-hover text-center mt-5">
            <thead className="text-center">
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Email</th>
                <th scope="col">Telefone</th>
                <th scope="col">Mensagem</th>
                <th scope="col">Data</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            {state.news && !state.loading && (
              <tbody>
                {state.news.map((dado) => {
                  const sizeMsg = Array.from(dado.message);
                  let msg = "";
                  let error = false;
                  sizeMsg.forEach((item, index) => {
                    if (index < 30) msg = msg + item;
                    else error = true;
                  });
                  // console.log(sizeMsg.join(''))
                  return (
                    <tr key={dado.id}>
                      <td>{dado.name}</td>
                      <td>{dado.email}</td>
                      <td>{dado.phone}</td>
                      {!error ? (
                        <td>{msg}</td>
                      ) : (
                        <td>
                          {msg}{" "}
                          <p
                            className="lead"
                            style={{ cursor: "pointer" }}
                            onClick={handleOpen}
                          >
                            ...ver mais
                          </p>
                        </td>
                      )}
                      <td>
                        {new Date(dado.created_at).toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </td>
                      {open && (
                        <td>
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Mensagem
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                              >
                                {dado.message}
                              </Typography>
                            </Box>
                          </Modal>
                        </td>
                      )}
                      <td>
                        {/* <IconButton size='large'>
                    <EmailIcon size='large' color='primary' onClick={() => ReplyMessage(dado.id)} />
                  </IconButton> */}

                        <IconButton
                          size="large"
                          onClick={() => renderAlert(dado.id)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {state.loading && (
            <div className="row">
              {" "}
              <div className="col-12 p-5 d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )}
          {state.news && pagination()}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ListNews;
