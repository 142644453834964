import React from 'react';
import { Link, Redirect, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useSelector } from 'react-redux';
import { Button, IconButton, Input, ThemeProvider, Pagination, CircularProgress } from '@mui/material';
import swal from 'sweetalert';
import Theme from '../../Theme/Theme';
import { URL } from 'utilities/variables';

const ListUser = () => {
  const [state, setState] = React.useState({
    users: [],
    pagination: { current_page: '', total_pages: '', per_page: '' },
    pageNumber: 1,
    loading: true,
    reload: false,
    loadingSearch: false,
    token: '',
    search: '',
  })
  const history = useNavigate();
  state.token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/user/list?page=${state.pageNumber}&search=${state.search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setState({
          ...state,
          users: json.users,
          pagination: json.pagination,
          loading: false,
          loadingSearch: false,
        })
      });
  }, [state.pageNumber, state.search, state.reload]);

  // function typeUser(dado) {
  //   if (dado.length != 0) {
  //     if (dado[0].adm == true) {
  //       return 'Administrador'
  //     } else if (dado[0].professor == true) {
  //       return 'Professor'
  //     } else if (dado[0].collaborator == true) {
  //       return 'Colaborador'
  //     } else if (dado[0].student == true) {
  //       return 'Aluno'
  //     }
  //   }
  //   return 'Indefinido'
  // }

  function renderAlert(id) {
    return (
      swal({
        title: "Deletar usuário selecionado?",
        text: "Uma vez deletado, não dará para recuperá-lo!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal("Usuário deletado com sucesso!", {
            icon: "success",
          });
          Delete(id)
          setState({ ...state, pageNumber: 0, reload: !state.reload })
        }
      })
    )
  }

  function Delete(id) {
    fetch(`${URL}api/user/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    });
  }

  function EditUser(id) {
    history('/dashboard/users/edit/' + id);
  }

  function pagination() {
    const { total_pages, per_page } = state.pagination;
    return (
      <div className='d-flex justify-content-end'>
        <Pagination color='primary' shape="rounded" count={Math.ceil(total_pages / per_page)} page={state.pageNumber} onChange={(e, page) => setState({ ...state, pageNumber: page })} />
      </div>
    )
  }

  let timer
  function search(e) {
    clearTimeout(timer);
    timer = setTimeout(() => { setState({ ...state, search: e.target.value, loadingSearch: true }) }, 750);
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className='card-body'>
          <div className="d-flex">
            <div className="flex-column">
              <h1 className='header-title'>Lista de Usuários</h1>
              <Input placeholder='Buscar...' endAdornment={state.loadingSearch ? <CircularProgress size={24} /> : <SearchIcon />} onChange={(e) => search(e)}></Input>
            </div>
            <div className="align-self-end ms-auto">
              <Button variant="contained" size='large' endIcon={<PersonAddIcon />} onClick={() => history('add')}>Adicionar</Button>
            </div>
          </div>
          <table className="table table-striped table-hover text-center mt-5">
            <thead className='text-center'>
              <tr>
                <th scope="col">Foto</th>
                <th scope="col">Nome</th>
                <th scope="col">Email</th>
                {/* <th scope="col">Usuário</th> */}
                <th scope="col">Ações</th>
              </tr>
            </thead>
            {state.users && !state.loading && <tbody>
              {state.users.map((dado) => {
                // let type_user = typeUser(dado.type_user);
                return (
                  <tr key={dado.id}>
                    <td><img className='m-auto' style={{ width: 75, height: 75, borderRadius: '50%' }} src={dado.file_path ? `${URL}storage/${dado.file_path}` : `${URL}storage/fotos/user_not_found.png`} alt="user" /></td>
                    <td>{dado.name}</td>
                    <td>{dado.email}</td>
                    {/* <td>{type_user}</td> */}
                    <td>
                      <IconButton size='large'>
                        <EditIcon size='large' color='secondary' onClick={() => EditUser(dado.id)} />
                      </IconButton>

                      <IconButton size='large'>
                        <DeleteIcon color='error' onClick={() => renderAlert(dado.id)} />
                      </IconButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>}
          </table>
          {state.loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>}
          {state.users && pagination()}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default ListUser