import { Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useInView } from "react-intersection-observer";
import ScrollTransition from "components/ScrollTransition";
import {brands} from "./brands"
import Depoimentos from "../Depoimentos";

const responsive = {
  0: {
    items: 2,
  },
  630: {
    items: 3,
  },
  830: {
    items: 4,
  },
  1000: {
    items: 5,
  },
  1230: {
    items: 6,
  },
};

const Clientes = () => {
  const [readyToRender, setReadyToRender] = useState(true);

  return (
    readyToRender && (
      <>
        <CustomSection
          bg_color="var(--background-color1)"
          style={{
            overflow: "hidden",
            position: "relative",
            minHeight: "auto",
          }}
          id="clientes"
        >
          <Container
            maxWidth="xl"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.8rem",
              margin: "auto",
              padding: "8rem 0",
              width: "100vw",
            }}
          >
            <ScrollTransition>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Nossos clientes
              </Typography>
            </ScrollTransition>

            <ScrollTransition
              sx={{
                margin: "0 auto",
                marginBottom: "2rem",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "1.1rem",
                  textAlign: "center",
                  margin: "0 auto",
                  fontWeight: "normal",
                  maxWidth: "30rem",
                  "&:after": {
                    content: "''",
                    display: "block",
                    marginTop: "30px",
                    width: "40px",
                    borderTop: "2px solid #444",
                    margin: "30px auto 0",
                  },
                }}
              >
                Conheça alguns de nossos clientes.
              </Typography>
            </ScrollTransition>

            <ScrollTransition>
              <OwlCarousel
                className="owl-theme"
                loop
                dots={false}
                nav={false}
                items={5}
                autoplay={true}
                autoplayTimeout={2000}
                responsive={responsive}
              >
                {brands.map((brand, index) => (
                  <div className="item" key={index} >
                    <a href={brand.link} target="_blank">
                      <img src={brand.image} 
                        style={{ 
                          width: "14rem", 
                          backgroundColor: "#f2f1f6",
                          background: 'linear-gradient(45deg, var(--accent-color3) -100%, #f2f1f6 40%, #f2f1f6 60%, var(--accent-color1) 180%)',
                          height: "10rem",
                          objectFit: "contain",
                          borderRadius: "0.6rem",
                          margin: "0.5rem",
                        }} 
                      />
                    </a>
                  </div>
                ))}
              </OwlCarousel>
            </ScrollTransition>
          </Container>
        </CustomSection>
        <Depoimentos />
      </>
    )
  );
};

export default Clientes;
