export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const image = file;
    if (!(file instanceof File))
      return 
    if (image && FileReader) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });


export const toggleAppTheme = () => {
  const app = document.querySelector(".App");

  if (app.className.includes("dark-mode")) {
    app.classList.remove("dark-mode");
    app.classList.add("white-mode");
    return 'white-mode'
  } else {
    app.classList.add("dark-mode");
    app.classList.remove("white-mode");
    return 'dark-mode'
  }
}

export const getAppTheme = () => {
  const app = document.querySelector(".App");
  if (app.className.includes("dark-mode")) {
    return 'dark-mode'
  } else {
    return 'white-mode'
  }
}