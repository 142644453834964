import { useState } from "react";
import "./index.modules.css";
import BackspaceIcon from "@mui/icons-material/Backspace";
import JoditEditor from "jodit-react";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [],
  height: 100,
  minHeight: 100,
  readonly: false,
  toolbarAdaptive: false,
  defaultLanguage: "pt_br",
  language: "pt_br",
};

const FormInput = (props) => {
  let { type, obj_key, content, fun_handle_form } = props;

  // console.log(content)
  const [title, setTitle] = useState(
    content !== null ? content.split("_{slice}_")[0] : ""
  );
  const [text, setText] = useState(
    content !== null ? content.split("_{slice}_")[1] : ""
  );

  const titleChange = (value) => {
    setTitle(value);
    fun_handle_form({
      key: obj_key,
      type: type,
      content: `${value}_{slice}_${text}`,
    });
  };

  const textChange = (value) => {
    setText(value);
    fun_handle_form({
      key: obj_key,
      type: type,
      content: `${title}_{slice}_${value}`,
    });
  };

  const deleteForm = () => {
    setTitle("");
    setText("");
    fun_handle_form({
      key: obj_key,
      type: type,
      content: null,
    });
  };

  return (
    <div className="form-input-policy-terms">
      <BackspaceIcon
        sx={{
          position: "absolute",
          right: "1rem",
          top: "1rem",
          cursor: "pointer",
          opacity: "0.5",
        }}
        onClick={deleteForm}
      />
      <input
        type="text"
        placeholder="Título..."
        value={title ? title : ""}
        onChange={({ target: { value } }) => titleChange(value)}
      />
      <JoditEditor
        config={JoditConfig}
        value={text || ""}
        onChange={(value) => textChange(value)}
      />
      {/* <textarea
        rows="6"
        placeholder="Texto..."
        value={text ? text : ""}
        onChange={({ target: { value } }) => textChange(value)}
      ></textarea> */}
    </div>
  );
};

export default FormInput;
