import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, CircularProgress, IconButton, Input, ThemeProvider, Pagination } from "@mui/material";
import { STORAGE_URL, URL } from "utilities/variables";
import Theme from '../../../Theme/Theme';

// mui icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import swal from "sweetalert";

const ListTags = () => {
  const history = useNavigate();

  const [posts, setPosts] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current_page: "",
    total_pages: "",
    per_page: "",
  });
  const [pageNumber, setPageNumber] = React.useState(1)
  const [loading, setLoading] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [token, setToken] = React.useState(
    useSelector((state) => state.AppReducer.token)
  );

  React.useEffect(() => {
    fetch(`${URL}api/blog/tags/list?page=${pageNumber}&search=${search}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setPosts(json.tags);
        setPagination(json.pagination);
        setLoading(false);
        setLoadingSearch(false)
      });
  }, [pageNumber, search, reload]);

  function renderAlert(id) {
    return (
      swal({
        title: "Deletar tag selecionada?",
        text: "Uma vez deletada, não dará para recuperá-la!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal("Tag deletada com sucesso!", {
            icon: "success",
          });
          setReload(!reload)
          Delete(id)
          setPageNumber(0)
        }
      })
    )
  }

  function renderTags() {
    return (
      <>
        <tbody>
          {posts.map((post, index) => {
            return (
              <tr key={index}>
                <td>{post.label}</td>
                <td>
                  <IconButton size="large">
                    <EditIcon
                      size="large"
                      color="secondary"
                      onClick={() => EditPost(post.id)}
                    />
                  </IconButton>

                  <IconButton size="large">
                    <DeleteIcon color="error" onClick={() => renderAlert(post.id)} />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }

  async function Delete(id) {
    try {
      let result = await fetch(`${URL}api/blog/tags/delete/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
      });
      // setState({...state, })
    } catch {
      console.log("deu bigode");
    }
  }

  function EditPost(id) {
    history("/dashboard/blog/tags/edit/" + id);
  }

  function renderPagination() {
    const { total_pages, per_page } = pagination;
    return (
      <div className='d-flex justify-content-end'>
        <Pagination color='primary' shape="rounded" count={Math.ceil(total_pages / per_page)} page={pageNumber} onChange={(e, page) => setPageNumber(page)} />
      </div>
    )
  }

  let timer
  function searchFunction(e) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setLoadingSearch(true)
      setSearch(e.target.value)
    }, 750);
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-column">
              <h1 className="header-title">Lista de tags das postagens</h1>
              <Input placeholder='Buscar...' endAdornment={loadingSearch ? <CircularProgress size={24} /> : <SearchIcon />} onChange={(e) => searchFunction(e)}></Input>
            </div>
            <div className="align-self-end ms-auto">
              <Button
                variant="contained"
                size="large"
                endIcon={<AddRoundedIcon />}
                onClick={() => history("/dashboard/blog/tags/add")}
              >
                Adicionar
              </Button>
            </div>
          </div>
          <table className="table table-striped table-hover text-center mt-5">
            <thead className="text-center">
              <tr>
                <th scope="col">Tag</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            {posts && loading == false && renderTags()}
          </table>
          {/* {loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>} */}
          {/* {posts && pagination()} */}
          {renderPagination()}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ListTags;
