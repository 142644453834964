import { Typography } from "@mui/material";

import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import style from "./style.module.css";
import { useEffect, useState } from "react";

const TestimoniesCard = ({
  index,
  name,
  title,
  text,
  avatar,
  occupation,
  remove,
  change,
}) => {

  const renderUserAvatar = (element) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      change(index, { avatar: e.target.result })
    };
    reader.readAsDataURL(element.files[0])
  };


  return (
    <div
      style={{
        // flexGrow: 1,
        flexBasis: "26rem",
        width: "26rem",
        gap: "1.2rem",
        display: "flex",
        flexDirection: "column",
        margin: "0.4rem 0.4rem",
        background: "#f1f1f1",
        padding: "1rem",
        borderRadius: "0.4rem",
        boxShadow: "0 0 0 0.1rem #e4dcec",
        position: "relative",
        height: 'max-content'
      }}
    >
      <HighlightOffIcon
        sx={{
          position: "absolute",
          top: "0.4rem",
          right: "0.4rem",
          opacity: 0.5,
          cursor: "pointer",
          color: "black",
          "&:hover": {
            opacity: 1,
          },
        }}
        onClick={() => remove(index)}
      />
      <div style={{ color: "#e9c46a" }}>
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
      </div>
      <Typography
        variant="body1"
        component="div"
        sx={{
          fontFamily: "var(--Raleway)",
          color: "black",
        }}
      >
        <textarea
          rows="4"
          placeholder="Depoimento..."
          className={style.cardInput}
          style={{ fontSize: "1.05rem", fontFamily: "var(--Raleway)", resize: "vertical" }}
          value={text}
          onInput={(e) => change(index, { text: e.target.value })}
        />
      </Typography>

      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <div>
          {/* <img
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "50%",
              backgroundPosition: "center",
              backgroundSize: "cover",
              boxShadow: "0 0 0 0.1rem rgba(105, 59, 159, 1)",
              objectFit: "cover",
            }}
            src={avatar_path}
          /> */}
          <label
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "50%",
              backgroundImage: `url(${avatar})`,
              backgroundSize: 'cover',
              boxShadow: "0 0 0 0.1rem rgba(105, 59, 159, 1)",
              cursor: "pointer",
            }}
          >
            <input
              type="file"
              style={{ display: "none" }}
              // onChange={(e) => change(index, { avatar_path: e.target.files[0] })}
              accept="image/gif, image/jpeg, image/png" 
              onChange={(e) => renderUserAvatar(e.target)}
            />
          </label>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
            {/* {name} */}
            <textarea
              rows="1"
              placeholder="Nome..."
              className={style.cardInput}
              style={{ fontWeight: "bold",fontSize: "1.4rem", fontFamily: "var(--Freight)",fontWeight: "bold",}}
              value={name}
              onInput={(e) => change(index, { name: e.target.value })}
            />
            {/* {occupation} */}
            <textarea
              rows="1"
              placeholder="Ocupação..."
              className={style.cardInput}
              style={{fontSize: "1rem", fontFamily: "var(--Raleway)" }}
              value={occupation}
              onInput={(e) => change(index, { occupation: e.target.value })}
            />
        </div>
      </div>
    </div>
  );
};

export default TestimoniesCard;
