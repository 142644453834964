import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import swal from 'sweetalert';
import form_styles from "components/SpecificStyles/form.module.css";
import { useState } from "react";
import { post } from "utilities/requests";
import CustomInput from "components/CustomInput";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

const Complaint = () => {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      swal("Erro!", "O campo nome é obrigatório!", "error");
      return;
    } else if (email === "") {
      swal("Erro!", "O campo e-mail é obrigatório!", "error");
      return;
    } else if (message === "") {
      swal("Erro!", "O campo mensagem está vazio... Por favor insira a denúncia.", "error");
      return;
    }

    if (loading) return;

    setLoading(true);

    let data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("message", message);

    let response = await post("complaints/add", data);

    if (response.status) {
      setName("");
      setEmail("");
      setMessage("");

      swal("Sucesso!", "Sua mensagem foi enviada com sucesso!", "success");
    } else {
      swal("Erro!", "Ocorreu um erro ao enviar sua mensagem, por favor entre em contato com o suporte.", "error");
    }

    setLoading(false);
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Faça uma denúncia
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "30rem",
          }}
        >
          Sentimos muito pelo ocorrido.
        </Typography>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
            width: "100%",
            maxWidth: "36rem",
          }}
          className={form_styles.customFormStyle}
          onSubmit={handleSubmit}
        >
          <CustomInput
            placeholder="Nome completo"
            icon={PersonIcon}
            value={name}
            onChange={setName}
            helperText="Insira seu nome civil ou social."
            required
          />
          <CustomInput
            type="email"
            placeholder="E-mail"
            icon={EmailIcon}
            value={email}
            onChange={setEmail}
            helperText="Insira seu e-mail para que possamos entrar em contato."
            required
          />
          <textarea
            placeholder="Mensagem"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <input type="submit" />
        </form>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "40",
            marginTop: "2rem",
          }}
        >
          Não se preocupe! Seus dados não serão compartilhados com terceiros.
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "40",
          }}
        >
          Sua denúncia será analisada e tomaremos as medidas cabíveis.
        </Typography>
      </Container>
    </CustomSection>
  );
};

export default Complaint;
