import cienciaconsumo from "assets/static/customers_brands/cienciaconsumo.png";
import eap from "assets/static/customers_brands/eap.png";
import expressao from "assets/static/customers_brands/expressao.png";
// import fmd from "assets/static/customers_brands/fmd.png";
import gondola from "assets/static/customers_brands/gondola.png";
import grupolife from "assets/static/customers_brands/grupolife.png";
import healthit from "assets/static/customers_brands/healthit.png";
import hefarma from "assets/static/customers_brands/hefarma.png";
import hubi from "assets/static/customers_brands/hubi.png";
// import intelipost from "assets/static/customers_brands/intelipost.png";
// import juno from "assets/static/customers_brands/juno.png";
import madelife from "assets/static/customers_brands/madelife.png";
import organrural from "assets/static/customers_brands/organrural.png";
// import pagarme from "assets/static/customers_brands/pagarme.png";
import verdaz from "assets/static/customers_brands/verdaz.png";
import visualsystems from "assets/static/customers_brands/visualsystems.png";
import colega from "assets/static/customers_brands/colega.png";
import ervision from "assets/static/customers_brands/ervision.png";
import rt from "assets/static/customers_brands/rt.png";
import nexuscare from "assets/static/customers_brands/nexuscare.png";
import bmwords from "assets/static/customers_brands/bmwords.png";

export const brands = [
  {
    image: cienciaconsumo,
    link: "https://brasil.gs/",
  },
  {
    image: eap,
    link: "https://www.eapc.com.br/",
  },
  {
    image: expressao,
    link: "/",
  },
  // {
  //   image: fmd,
  //   link: "/",
  // },
  {
    image: gondola,
    link: "https://appgondola.com.br/",
  },
  {
    image: grupolife,
    link: "https://www.grupolife.med.br/",
  },
  {
    image: healthit,
    link: "https://hit.com.vc/",
  },
  {
    image: hefarma,
    link: "https://hefarma.com.br/",
  },
  {
    image: hubi,
    link: "/",
  },
  // {
  //   image: intelipost,
  //   link: "/",
  // },
  // {
  //   image: juno,
  //   link: "/",
  // },
  {
    image: madelife,
    link: "https://madelife.com.br/",
  },
  {
    image: organrural,
    link: "https://organrural.com.br/",
  },
  // {
  //   image: pagarme,
  //   link: "/",
  // },
  {
    image: verdaz,
    link: "http://www.verdaz.com.br/",
  },
  {
    image: visualsystems,
    link: "https://www.visualsystems.com.br/",
  },
  {
    image: colega,
    link: "https://colegabrasil.com.br/",
  },
  {
    image: ervision,
    link: "https://er.vision/",
  },
  {
    image: rt,
    link: "https://rt.enterscience.com.br/",
  },
  {
    image: nexuscare,
    link: "/",
  },
  {
    image: bmwords,
    link: "https://www.bmwords.com.br/",
  },
]